import { IncomingMessage } from 'http';

import Cookies from 'js-cookie';

import { fechFromBPartsApi } from './utils';

type StocksArray = Array<{
  category_identifier?: string;
  part_identifier?: string;
  car_identifier?: string;
}>;

type StocksOptions = {
  no_round: boolean;
  stocks: StocksArray;
};

export const fetchStocks = (
  {
    locale,
    options,
  }: {
    locale: string;
    options: StocksOptions;
  },
  req?: IncomingMessage
) => {
  return fechFromBPartsApi(
    `/${locale}/shop/stocks`,
    {
      method: 'post',
      headers: new Headers({
        ...(!!req ? (req?.headers as HeadersInit) : {}),
        'Content-Type': 'application/json',
        cookie: '',
        'X-CSRFToken': Cookies.get('csrftoken') || '',
      } as HeadersInit),

      credentials: 'include',

      body: JSON.stringify(options),
    },
    { enableCustomErrorWrapper: true }
  );
};

export const fetchStocksForCategories = async (
  {
    locale,
    categories,
    vehicle,
  }: {
    locale: string;
    categories: Array<string>;
    vehicle?: string;
  },
  req?: IncomingMessage
) => {
  const stocks: StocksArray = categories.map((c) => ({
    category_identifier: c,
    ...(vehicle ? { car_identifier: vehicle } : {}),
  }));

  const options: StocksOptions = { no_round: true, stocks };

  return await fetchStocks({ locale, options }, req);
};

export const fetchStocksForVehicles = async (
  {
    locale,
    category,
    part,
    vehicles,
  }: {
    locale: string;
    category?: string;
    part?: string;
    vehicles: Array<string>;
  },
  req?: IncomingMessage
) => {
  const stocks: StocksArray = vehicles.map((v) => ({
    car_identifier: v,
    ...(category ? { category_identifier: category } : {}),
    ...(part ? { part_identifier: part } : {}),
  }));

  const options: StocksOptions = { no_round: false, stocks };

  return await fetchStocks({ locale, options }, req);
};
