import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { ComponentProps, FunctionComponent, ReactNode, memo } from 'react';

import useLinkProps from '../../../../hooks/use-link-props';
import FeaturedLink from '../../../ui/links/featured';
import TextLink from '../../../ui/links/text';
import SupportBanner from '../../../ui/support-banner';

import classes from './value-proposition.module.css';

type TMessage = {
  variant: ComponentProps<typeof SupportBanner>['variant'];
  alt: string;
  title: ReactNode;
  text: ReactNode;
};

const ValueProposition: FunctionComponent<{
  variant?: Pick<TMessage, 'variant'>;
  infoToDisplayInTitle?: string;
  isHome?: boolean;
}> = memo(
  ({ variant = 'PRIMARY', infoToDisplayInTitle = '', isHome = false }) => {
    const { t } = useTranslation();
    const getLink = useLinkProps();

    const messages = t('value-proposition:messages', undefined, {
      returnObjects: true,
    }) as unknown as Array<TMessage>;

    return (
      <section
        className={cx(variant === 'SECONDARY' && 'bg-neutral-10', 'py-20')}
      >
        {!isHome && (
          <span
            className={cx(
              'container mx-auto',
              'block',
              'font-bold',
              'max-lg:px-8',
              'text-[22px]',
              'lg:text-[32px]',
              'text-center',
              'mb-6'
            )}
          >
            {t('value-proposition:title', { info: infoToDisplayInTitle })}
          </span>
        )}
        <div
          className={cx(
            classes.support,
            'pt-4 px-6 lg:px-4 lg:pb-5 text-center container m-auto '
          )}
        >
          {messages.map((message) => (
            <SupportBanner
              key={message.variant}
              alt={message.alt}
              title={message.title}
              text={message.text}
              variant={message.variant}
              className={classes.item}
            />
          ))}
        </div>
        <div className={cx('container w-full m-auto text-center')}>
          <span className={cx('font-bold', 'leading-6', 'text-2xl')}>
            {t('value-proposition:pre-link')}
          </span>
          <div className="mt-2">
            <Link {...getLink('contacts')} passHref legacyBehavior>
              <FeaturedLink variant="blue" disableFocusOnMenu>
                {t('value-proposition:link')}
              </FeaturedLink>
            </Link>
          </div>
        </div>
      </section>
    );
  }
);

ValueProposition.displayName = 'ValueProposition';

export default ValueProposition;
