import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { ComponentProps, FunctionComponent, ReactNode, memo } from 'react';

import useLinkProps from '../../../hooks/use-link-props';
import FeaturedLink from '../../ui/links/featured';
import SupportBanner from '../../ui/support-banner';

import classes from './value-proposition.module.css';

type TMessage = {
  variant: ComponentProps<typeof SupportBanner>['variant'];
  alt: string;
  title: ReactNode;
  text: ReactNode;
};

const ValueProposition: FunctionComponent<{
  variant?: Pick<TMessage, 'variant'>;
}> = memo(({ variant = 'PRIMARY' }) => {
  const { t } = useTranslation();
  const getLink = useLinkProps();

  const messages = t('value-proposition:messages', undefined, {
    returnObjects: true,
  }) as unknown as Array<TMessage>;

  return (
    <section className={cx(variant === 'SECONDARY' && 'bg-neutral-10')}>
      <div
        className={cx(
          classes.support,
          'py-20 px-6 lg:px-4 text-center container m-auto'
        )}
      >
        {messages.map((message) => (
          <SupportBanner
            key={message.variant}
            alt={message.alt}
            title={message.title}
            text={message.text}
            variant={message.variant}
            className={classes.item}
          />
        ))}
        <small className={cx(classes.description, 'text-sm w-full')}>
          <span className={cx('font-bold', 'leading-5', 'tracking-wider')}>
            {t('value-proposition:pre-link')}
          </span>
          <div>
            <Link {...getLink('contacts')} passHref legacyBehavior>
              <FeaturedLink disableFocusOnMenu>
                <span className={cx('text-2xl')}>
                  {t('value-proposition:link')}
                </span>
              </FeaturedLink>
            </Link>
          </div>
        </small>
      </div>
    </section>
  );
});

ValueProposition.displayName = 'ValueProposition';

export default ValueProposition;
