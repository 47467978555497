import { FunctionComponent } from 'react';

const ProtectSvg: FunctionComponent<{ title?: string; className?: string }> = ({
  title,
  ...props
}) => (
  <svg
    {...props}
    width="38"
    height="40"
    viewBox="0 0 38 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6316 11.843C27.1276 12.2948 27.1817 13.0842 26.7523 13.6062L17.2597 25.1447C17.0452 25.4055 16.7378 25.5614 16.4103 25.5756C16.0828 25.5897 15.7643 25.4609 15.5302 25.2195L11.3151 20.8746C10.8464 20.3914 10.8387 19.6 11.2978 19.1069C11.757 18.6137 12.5091 18.6056 12.9778 19.0887L16.2903 22.5033L24.9558 11.97C25.3852 11.448 26.1355 11.3912 26.6316 11.843Z"
      fill="#359AD2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5114 0.110789C18.8223 -0.0369295 19.1785 -0.0369295 19.4894 0.110789C26.3177 3.35556 29.3914 4.39508 36.9529 5.82225C37.4915 5.92389 37.8944 6.39937 37.9288 6.9739C39.3694 30.9986 20.8454 39.3159 19.4442 39.9097C19.1594 40.0303 18.8414 40.0303 18.5566 39.9097C17.1554 39.3159 -1.36863 30.9986 0.0719871 6.9739C0.106438 6.39937 0.509348 5.92389 1.04787 5.82225C8.60937 4.39508 11.6831 3.35556 18.5114 0.110789ZM2.39616 8.10516C1.66079 28.1788 16.0713 36.0099 19.0004 37.3907C21.9295 36.0099 36.34 28.1788 35.6046 8.10516C28.6753 6.75849 25.3897 5.63652 19.0004 2.6208C12.6111 5.63652 9.32553 6.75849 2.39616 8.10516Z"
      fill="#359AD2"
    />
  </svg>
);

export default ProtectSvg;
