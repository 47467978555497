import { FunctionComponent } from 'react';

const StockSvg: FunctionComponent<{ title: string; className?: string }> = ({
  title,
  ...props
}) => (
  <svg
    {...props}
    width="38"
    height="34"
    viewBox="0 0 38 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.98844 3.25317C7.61676 3.25317 6.50597 4.03481 6.09792 5.03662L2.27241 14.4284C2.01394 15.0629 1.31555 15.3569 0.712493 15.0849C0.109439 14.813 -0.169908 14.0781 0.0885532 13.4436L3.91405 4.05183C4.75147 1.99587 6.81409 0.753174 8.98844 0.753174H29.0151C31.1895 0.753174 33.2521 1.99586 34.0895 4.05183L37.915 13.4436C38.1735 14.0781 37.8941 14.813 37.2911 15.0849C36.688 15.3569 35.9896 15.0629 35.7311 14.4284L31.9056 5.03664C31.4976 4.03482 30.3868 3.25317 29.0151 3.25317H8.98844Z"
      fill="#359AD2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-0.00738765 13.9351C-0.0073873 13.2448 0.524493 12.6851 1.1806 12.6851H36.8232C37.1383 12.6851 37.4404 12.8168 37.6632 13.0512C37.886 13.2856 38.0112 13.6036 38.0112 13.9351L38.0112 28.2532C38.0112 28.9435 37.4793 29.5032 36.8232 29.5032H1.1806C0.865517 29.5032 0.563348 29.3715 0.340557 29.1371C0.117766 28.9026 -0.00739629 28.5847 -0.00739615 28.2532L-0.00738765 13.9351ZM2.36859 15.1851L2.36858 27.0032H35.6352L35.6352 15.1851H2.36859Z"
      fill="#359AD2"
    />
    <path d="M4.42232 28.2532V31.9995H1.1806V28.2532" fill="#359AD2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.1806 27.0032C1.8367 27.0032 2.36858 27.5628 2.36858 28.2532V30.7495H3.23433V28.2532C3.23433 27.5628 3.76621 27.0032 4.42232 27.0032C5.07842 27.0032 5.6103 27.5628 5.6103 28.2532V31.9995C5.6103 32.6898 5.07842 33.2495 4.42232 33.2495H1.1806C0.524489 33.2495 -0.00739161 32.6898 -0.00739161 31.9995L-0.00739615 28.2532C-0.00739615 27.5628 0.524489 27.0032 1.1806 27.0032Z"
      fill="#359AD2"
    />
    <path d="M36.8246 28.2532V31.9983H33.5828V28.2532" fill="#359AD2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.5828 27.0032C34.2389 27.0032 34.7708 27.5628 34.7708 28.2532V30.7483H35.6366V28.2532C35.6366 27.5628 36.1685 27.0032 36.8246 27.0032C37.4807 27.0032 38.0112 27.5628 38.0112 28.2532L38.0125 31.9983C38.0125 32.6887 37.4807 33.2483 36.8246 33.2483H33.5828C32.9267 33.2483 32.3949 32.6887 32.3949 31.9983V28.2532C32.3949 27.5628 32.9267 27.0032 33.5828 27.0032Z"
      fill="#359AD2"
    />
    <path
      d="M8.52373 25.0704C10.4921 25.0704 12.0877 23.3914 12.0877 21.3204C12.0877 19.2493 10.4921 17.5704 8.52373 17.5704C6.55541 17.5704 4.95977 19.2493 4.95977 21.3204C4.95977 23.3914 6.55541 25.0704 8.52373 25.0704Z"
      fill="#359AD2"
    />
    <path
      d="M29.9075 25.0704C31.8758 25.0704 33.4715 23.3914 33.4715 21.3204C33.4715 19.2493 31.8758 17.5704 29.9075 17.5704C27.9392 17.5704 26.3435 19.2493 26.3435 21.3204C26.3435 23.3914 27.9392 25.0704 29.9075 25.0704Z"
      fill="#359AD2"
    />
  </svg>
);

export default StockSvg;
