import { FunctionComponent } from 'react';

const PaymentSvg: FunctionComponent<{ title: string; className?: string }> = ({
  title,
  ...props
}) => (
  <svg
    {...props}
    viewBox="0 0 50 39"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M42.5385 1H7.46154C3.89293 1 1 3.89293 1 7.46154V31.4615C1 35.0301 3.89293 37.9231 7.46154 37.9231H42.5385C46.1071 37.9231 49 35.0301 49 31.4615V7.46154C49 3.89293 46.1071 1 42.5385 1Z"
      stroke="#F0C777"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 12.0769H49H1ZM10.2308 24.5384H15.7692V26.8461H10.2308V24.5384Z"
      stroke="#F0C777"
      strokeLinejoin="round"
    />
  </svg>
);

export default PaymentSvg;
