import { IncomingMessage } from 'http';

import { fetchStocksForVehicles } from './stocks';
import { fechFromBPartsApi } from './utils';

export const fetchBrands = (
  {
    locale,
  }: {
    locale: string;
  },
  req?: IncomingMessage
) =>
  fechFromBPartsApi(
    `/${locale}/shop/cars/by_slug`,
    {
      credentials: 'include',
      headers: req ? new Headers(req.headers as HeadersInit) : undefined,
    },
    { enableCustomErrorWrapper: true }
  );

export const fetchModels = (
  {
    locale,
    slug,
    top_models = false,
  }: {
    locale: string;
    slug: string;
    top_models?: boolean;
  },
  req?: IncomingMessage
): Promise<TResultAPICarsBySlug> =>
  fechFromBPartsApi<TResultAPICarsBySlug>(
    `/${locale}/shop/cars/by_slug?slug=${encodeURI(slug)}${
      top_models ? '&top_models=1' : ''
    }`,
    {
      credentials: 'include',
      headers: req ? new Headers(req.headers as HeadersInit) : undefined,
    },
    {
      enableCustomErrorWrapper: true,
    }
  );

export const fetchVersions = (
  {
    locale,
    slug,
  }: {
    locale: string;
    slug: string;
  },
  req?: IncomingMessage
) =>
  fechFromBPartsApi(
    `/${locale}/shop/cars/by_slug?slug=${encodeURI(slug)}`,
    {
      credentials: 'include',
      headers: req ? new Headers(req.headers as HeadersInit) : undefined,
    },
    { enableCustomErrorWrapper: true }
  );

export const fetchByPlateOrVin = (
  {
    locale,
    slug,
  }: {
    locale: string;
    slug: string;
  },
  req?: IncomingMessage
) =>
  fechFromBPartsApi(
    `/${locale}/shop/cars/search_by_plate_or_vin?search=${encodeURI(slug)}`,
    {
      credentials: 'include',
      headers: req ? new Headers(req.headers as HeadersInit) : undefined,
    },
    { enableCustomErrorWrapper: true }
  );

export const fetchByKba = ({
  locale,
  hsn,
  tsn,
}: {
  locale: string;
  hsn: string;
  tsn: string;
}) =>
  fechFromBPartsApi(
    `/${locale}/shop/cars/search_by_kba?search_hsn=${encodeURI(
      hsn
    )}&search_tsn=${encodeURI(tsn)}`,
    { credentials: 'include' },
    { enableCustomErrorWrapper: true }
  );

export const fetchVehicle = (
  {
    locale,
    slug,
  }: {
    locale: string;
    slug: string;
  },
  req?: IncomingMessage
) =>
  fechFromBPartsApi(
    `/${locale}/shop/cars/by_slug?slug=${encodeURI(slug)}`,
    {
      credentials: 'include',
      headers: req ? new Headers(req.headers as HeadersInit) : undefined,
    },
    { enableCustomErrorWrapper: true }
  );

export const fetchBrandsWithStocks = async (
  {
    locale,
    category,
    part,
  }: {
    locale: string;
    category?: string;
    part?: string;
  },
  req?: IncomingMessage
) => {
  const brands: TResultAPICarsBySlug = await fetchBrands({ locale }, req);

  return fetchVehicleWithStocks(
    {
      locale,
      category,
      part,
      vehicles: brands,
    },
    req
  );
};

export const fetchModelsWithStocks = async (
  {
    locale,
    slug,
    category,
    part,
  }: {
    locale: string;
    slug: string;
    category?: string;
    part?: string;
  },
  req?: IncomingMessage
) => {
  const models: TResultAPICarsBySlug = await fetchModels({ locale, slug }, req);

  return fetchVehicleWithStocks(
    {
      locale,
      category,
      part,
      vehicles: models,
    },
    req
  );
};

export const fetchVersionsWithStocks = async (
  {
    locale,
    slug,
    category,
    part,
  }: {
    locale: string;
    slug: string;
    category?: string;
    part?: string;
  },
  req?: IncomingMessage
) => {
  const versions: TResultAPICarsBySlug = await fetchVersions(
    { locale, slug },
    req
  );

  return fetchVehicleWithStocks(
    {
      locale,
      category,
      part,
      vehicles: versions,
    },
    req
  );
};

export const fetchVehicleWithStocks = async (
  {
    locale,
    category,
    part,
    vehicles,
  }: {
    locale: string;
    category?: string;
    part?: string;
    vehicles: TResultAPICarsBySlug;
  },
  req?: IncomingMessage
) => {
  const vehicleSglus = vehicles.children.map((v) => v.slug);

  const stocks = await fetchStocksForVehicles(
    {
      locale,
      category,
      part,
      vehicles: vehicleSglus,
    },
    req
  );

  vehicles.info.total_products = stocks.reduce(
    (total: number, v: TResultAPIStocksItem) => (total += v.stock),
    0
  );

  return vehicles;
};
